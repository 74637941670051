












































































































































import { Component, Vue } from "vue-property-decorator";
import StorageService from "@/api/storage";

@Component({})
export default class Bodegas extends Vue {
  isHovering = false;
  isAddingBodega = false;
  isAddingUser = false;
  selectedBodega = null;
  owned = [];
  memberOf = [];
  bodegaAlias = "";
  
  toggleUserBodega(bodega: any) {
    this.selectedBodega = bodega;
    this.isAddingUser = true;
  }

  crearBodega() {
    if (this.bodegaAlias == "") {
      this.$buefy.notification.open({
        message: "El alias no puede estar vacio",
        type: "is-danger"
      });
    }
    StorageService.create({
      alias: this.bodegaAlias
    })
      .then(response => {
        this.$store.dispatch("UserModule/setStorage", response.data);
        StorageService.get().then(response => {
          this.owned = response.data.own;
          this.memberOf = response.data.member_of;
          this.isAddingBodega = false;
        });
      })
      .catch(() =>
        this.$buefy.notification.open({
          message: "Ha ocurrido un error al crear la bodega",
          type: "is-danger"
        })
      );
  }

  mounted() {
    StorageService.get().then(response => {
      this.owned = response.data.own;
      this.memberOf = response.data.member_of;
    });
  }
}
